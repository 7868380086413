const ContactFocus = document.querySelectorAll(".gofocus")
const FieldFocus = document.querySelector(".autofocus")


ContactFocus.forEach(el => {
    el.addEventListener("click", (e) => {
        FieldFocus.focus({preventScroll:false});
    })
})



// document.querySelectorAll('a[href^="/#"]').forEach(anchor => {
//     anchor.addEventListener('click', function (e) {
//         e.preventDefault();
//         document.querySelector("#"+anchor.name).scrollIntoView({
//             behavior: 'smooth'
//         });
//     });
// });